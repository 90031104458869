import React from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'

import GlobalEventsContext from '@shared/components/global-events/GlobalEventsContext'
import useApp from '@shared/hooks/useApp'

const ErrorBoundary = dynamic(() => import('@shared/components/errorBoundary'))
const MyApolloProvider = dynamic(() => import('@graphql/index'))
// const DownloadApp = dynamic(() => import('@shared/components/downloadApp'), { ssr: false })
const MainHeader = dynamic(() => import('@shared/components/header'))
const Footer = dynamic(() => import('@shared/components/footer'))
const Firebase = dynamic(() => import('@shared/components/pushNotification/firebase'))
const ToastrWrapper = dynamic(() => import('@shared/components/toastr'))
// const OnMouseAndScroll = dynamic(() => import('@shared/components/lazyLoad/onMouseAndScroll'))

function AppContent({ children }) {
  const { isAmp, isPreview, stateGlobalEvents, dispatchGlobalEvents, isGlanceView, router } = useApp()

  return (
    <ErrorBoundary>
      <GlobalEventsContext.Provider value={{ stateGlobalEvents, dispatchGlobalEvents }}>
        <MyApolloProvider router={router}>
          {/* <OnMouseAndScroll> */}
          <Firebase />
          {/* </OnMouseAndScroll> */}
          {/* {!isAmp && !isPreview && !isGlanceView && <DownloadApp />} */}
          {!isAmp && !isPreview && <MainHeader />}
          {children}
          {!isAmp && !isPreview && !isGlanceView && <Footer />}
        </MyApolloProvider>
        <ToastrWrapper />
      </GlobalEventsContext.Provider>
    </ErrorBoundary>
  )
}
AppContent.propTypes = {
  children: PropTypes.node.isRequired
}
export default React.memo(AppContent)
